<template>
  <v-row no-gutters class="ma-0">
   <v-col class="text-center background mt-6 px-0 pb-4 " :class="[{'ml-n12' : !$store.state.base.isExpandNavigationDrawer}, `${$route.name !== 'AccountMembers' ? 'pagination' : 'paginationMember'}`]" :style="$store.state.base.isExpandNavigationDrawer ? `${$route.name !== 'AccountMembers' ? 'margin-left: -150px' : ''}` : ''">
    <v-btn class="subtitle-2" @click="previous" :disabled="page === 1" color="secondary" text :ripple="false">
      <v-icon class="mr-3">mdi-chevron-left</v-icon> Anterior
    </v-btn>
    <v-icon class="mx-5" color="secondary" size="8 ">mdi-circle</v-icon>
    <v-btn class="subtitle-2 font-weight-medium pa-0 btnPage" color="primary" icon small :ripple="false">{{ page }}</v-btn>
    <v-icon class="mx-5" color="secondary" size="8 ">mdi-circle</v-icon>
    <v-btn class="subtitle-2" @click="next" :disabled="disabledNext" color="secondary" text :ripple="false">
      Siguiente <v-icon class="ml-3">mdi-chevron-right</v-icon>
    </v-btn>
  </v-col>
</v-row>
</template>

<script>
import ListViewMixin from '@/mixins/ListViewMixin'

export default {
  mixins: [
    ListViewMixin
  ],
  props: {
    titleShowAll: {
      type: String,
      default: 'Ver todos los documentos'
    },
    redirect: {
      type: String,
      default: null
    },
    count: {
      default: null
    },
    pageSize: {
      default: null
    }
  },
  data: () => ({
    currentPage: null,
    totalRegistrosPerPagina: 0
  }),
  computed: {
    disabledNext () {
      return this.$route.name === 'DocumentsList' ? ((parseInt(this.$route.query.page) === this.mainTotalPages) || (this.pageSize >= this.count)) : !this.count
    },
    mainTotalPages () {
      return this.count > this.pageSize ? Math.ceil(this.count / this.pageSize) : 1
    }
  },
  methods: {
    previous () {
      if (this.page > 1) this.page -= 1
    },
    next () {
      const validate = this.$route.name === 'DocumentsList' ? this.page <= this.mainTotalPages : this.count
      if (validate) this.page += 1
    }
  }
}
</script>