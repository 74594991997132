<template>
  <thead>
    <!-- <v-divider style="left: -1px; min-width: 100% !important; position: absolute;" :style="`top: ${lineBottomHeader}px;`" /> -->
    <tr>
      <th v-for="header in headers" :key="header.value" @click="header.sortable ? $emit('sort', header.value) : null" :width="header.value === 'data-table-select' ? 20 : header.width" :class="header.class">
        <v-row class="ma-0" align="center" :justify="header.align" :class="header.class">
          <div class="d-flex align-center ma-0">
            <v-checkbox class="custom-checkbox pa-0" v-if="header.value === 'data-table-select'" :input-value="header.all || !!$store.state.documents.sendTypeToggleDropdown" @change="$emit('isSelected', $event)" dense color="primary" hide-details @click="$emit('toggleAll')" />
            <v-menu v-if="header.value === 'data-table-select'" close-on-click close-on-content-click offset-y right :nudge-bottom="10" :width="60" transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon class="ml-n3 mr-n3" size="25" left>mdi-menu-down</v-icon>
              </span>
              </template>
              <v-list class="pa-0">
                <v-list-item class="px-3" :input-value="$store.state.documents.sendTypeToggleDropdown === 'all'" @click="selectedItem('all')" :ripple="false"> <!--$emit('toggleDropdown', 'all')-->
                  <v-list-item-title class="body-2">Todos</v-list-item-title>
                </v-list-item>
                <v-list-item class="px-3" :input-value="$store.state.documents.sendTypeToggleDropdown === 'filtered'" @click="selectedItem('filtered')" :disabled="!Object.keys($route.query).length || !!$route.query.ordering" :ripple="false">
                  <v-list-item-title class="body-2">Filtrados</v-list-item-title>
                </v-list-item>
                <v-list-item class="px-3" :input-value="$store.state.documents.sendTypeToggleDropdown === 'perPage'" @click="selectedItem('perPage')" :ripple="false">
                  <v-list-item-title class="body-2">Primera página</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <span>{{header.text}}
            <template v-if="header.sortable && !['PosRetrieveUpdate', 'BranchRetrieveUpdate', 'DocumentsRetrieve', 'CustomerRetrieveUpdate', 'PurchaseRetrieve', 'SupplierRetrieveUpdate'].includes($route.name)">
              <v-icon color="secondary" x-small>mdi-swap-vertical-bold</v-icon>
              <!-- <v-icon class="sorting sort" v-if="![header.value, `-${header.value}`].includes($route.query.ordering)" /> -->
              <!-- <v-icon class="sorting sort-Up" v-if="$route.query.ordering === `-${header.value}`" />
              <v-icon class="sorting sort-Down" v-if="$route.query.ordering === header.value" /> -->
            </template>
          </span>
        </v-row>
      </th>
    </tr>
  </thead>
</template>
<script>
export default {
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    resetCheckbox: {
      type: Number,
      default: 0
    }
  },
  computed: {
    lineBottomHeader () {
      if (['FoliosRetrieve', 'LogsList', 'SentDocumentList', 'DocumentSummary'].includes(this.$route.name)) return 138
      // else if (['PosRetrieveUpdate', 'BranchRetrieveUpdate'].includes(this.$route.name)) return 87
      else return 200
    }
  },
  data: () => ({
    dropdown: '',
    oldSelected: null
  }),
  methods: {
    selectedItem (type) {
      this.dropdown = this.oldSelected !== type
      this.$emit('toggleDropdown', this.dropdown ? type : '')
      this.$store.commit('documents/SET_TOOGLE_SENDTYPE', this.dropdown ? type : '')
      this.oldSelected = type
    }
  }
}
</script>
<style scoped>
.sorting.sort {
  background-image: url('../../assets/appIcon-ui--tableSort.svg');
  height: 9px;
  width: 10px;
  left: 2px;
}
.sorting.sort-Up {
  background-image: url('../../assets/appIcon-ui--tableSortUp.svg');
  height: 9px;
  width: 10px;
  left: 2px;
}
.sorting.sort-Down {
  background-image: url('../../assets/appIcon-ui--tableSortDown.svg');
  height: 9px;
  width: 10px;
  left: 2px;
}
</style>