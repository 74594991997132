<template>
  <v-row>
    <v-col cols="12" :class="`text-center ${isDetail ? 'pb-5 pt-0' : ''}`">
      <v-row class="my-2" align="center" justify="center">
        <v-img contain :height="isDetail ? 260 : 400" :src="require(`@/assets/empty-state/appAsset-http--403-ax.svg`)" />
      </v-row>
      <span :class="`d-block subtitle-2 font-weight-bold ${isDetail ? 'mt-n5' : 'mt-n9'}`">No tienes permisos para visualizar esta sección</span>
      <span class="d-block fontDraw--text subtitle-2">Ponte en contacto con el administrador para solicitar este permiso.</span>
      <v-btn v-if="!isDetail" class="mt-5" :to="{name: 'AccountDashboard'}" :width="220" color="primary">Volver al inicio</v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    isDetail: {
      type: Boolean,
      default: false
    }
  }
}
</script>