export default [
  {
    id: "pur_FqJlLtMn3y6snYf0IR",
    labels: [],
    amount: 42008,
    date: "2024-01-26",
    discount: null,
    discount_scheme: null,
    exempt: 0,
    metadata: {},
    number: 7919,
    prefix: null,
    subtotal: null,
    tax: 7982,
    tax_percent: 19,
    total: 49990,
    created: "2024-01-26T11:01:37.750609-03:00",
    updated: "2024-01-26T11:01:37.750629-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_OO25zO4TmqoolWlzQd",
      tax_settings: {
        cl_activity_description: "VTA.PARTES-PIEZAS-ACCES VEHICULOS-LUBRICENTRO-ART.FERRETERIA Y MAT CON"
      },
      name: "BOTICAS Y SALUD S.A.C.",
      tax_id: "20608430301",
      address: "SAN MARTIN 101",
      state: "BUIN",
      email: null,
      phone: null,
      website: null,
      created: "2022-11-29T14:22:41.647161-03:00",
      updated: "2022-11-29T14:22:41.647184-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_FaqoB2FOWfgfNs5PD9",
    labels: [],
    amount: 12328,
    date: "2024-01-25",
    discount: null,
    discount_scheme: null,
    exempt: 0,
    metadata: {},
    number: 13266531,
    prefix: null,
    subtotal: null,
    tax: 2342,
    tax_percent: 19,
    total: 14670,
    created: "2024-01-25T21:30:19.930668-03:00",
    updated: "2024-01-25T21:30:19.930688-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_96zTW6TsBKUoucZNFD",
      tax_settings: {
        cl_activity_description: "SEGUROS GENERALES"
      },
      name: "BOTICAS Y SALUD S.A.C.",
      tax_id: "20100061474",
      address: "AV. MANQUEHUE NORTE 290 OF. 101 PISO 1",
      state: "SANTIAGO",
      email: null,
      phone: null,
      website: null,
      created: "2024-01-25T21:30:19.899801-03:00",
      updated: "2024-01-25T21:30:19.899825-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_5GXCwhI0EJPLwGUXla",
    labels: [],
    amount: 684637,
    date: "2024-01-25",
    discount: null,
    discount_scheme: null,
    exempt: 13962,
    metadata: {},
    number: 29639331,
    prefix: null,
    subtotal: null,
    tax: 130081,
    tax_percent: 19,
    total: 828680,
    created: "2024-01-25T21:30:12.278176-03:00",
    updated: "2024-01-25T21:30:12.278206-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_tPhkgROgOPCyTkObK7",
      tax_settings: {
        cl_activity_description: "DIST. Y VTA. ENERG ELÉCT, ART. ELÉCT. HOG, DEP, ESPARC, COMP."
      },
      name: "REPRESENTACIONES DECO S.A.C.",
      tax_id: "20100061474",
      address: "SANTA ROSA 76 PISO 8",
      state: "SANTIAGO",
      email: null,
      phone: null,
      website: null,
      created: "2022-11-29T09:30:46.851316-03:00",
      updated: "2022-11-29T09:30:46.851339-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_kWgmYGuPqpNDg7u5wz",
    labels: [],
    amount: 23339,
    date: "2024-01-25",
    discount: null,
    discount_scheme: null,
    exempt: 0,
    metadata: {},
    number: 446307,
    prefix: null,
    subtotal: null,
    tax: 4434,
    tax_percent: 19,
    total: 30000,
    created: "2024-01-25T09:00:38.190419-03:00",
    updated: "2024-01-25T09:00:38.190441-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_HxX82zPYtx7BcRDS82",
      tax_settings: {
        cl_activity_description: "ESTACION DE SERVICIO"
      },
      name: "REPRESENTACIONES DECO S.A.C.",
      tax_id: "20100061474",
      address: "Avda. Vicuna Mackenna 360",
      state: "Melipilla",
      email: null,
      phone: null,
      website: null,
      created: "2022-11-29T02:35:47.600065-03:00",
      updated: "2022-11-29T02:35:47.600089-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_wgC9iMge9nYeHMN6vK",
    labels: [],
    amount: 8830,
    date: "2024-01-23",
    discount: null,
    discount_scheme: null,
    exempt: 0,
    metadata: {},
    number: 14652163,
    prefix: null,
    subtotal: null,
    tax: 1678,
    tax_percent: 19,
    total: 10508,
    created: "2024-01-23T21:30:12.127312-03:00",
    updated: "2024-01-23T21:30:12.127336-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_LYjn0CCbK4FudxHOhh",
      tax_settings: {
        cl_activity_description: "Servicios de Telefonia Movil"
      },
      name: "Homecenters Peruanos Oriente S.A.C",
      tax_id: "20394077101",
      address: "El Salto 5450",
      state: "Huechuraba",
      email: null,
      phone: null,
      website: null,
      created: "2022-11-29T10:00:47.402154-03:00",
      updated: "2022-11-29T10:00:47.402177-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_wRnKAAqTdaqSqND7u5",
    labels: [],
    amount: 96949,
    date: "2024-01-20",
    discount: null,
    discount_scheme: null,
    exempt: 0,
    metadata: {},
    number: 10803970,
    prefix: null,
    subtotal: null,
    tax: 18420,
    tax_percent: 19,
    total: 115369,
    created: "2024-01-22T16:02:05.764156-03:00",
    updated: "2024-01-22T16:02:05.764186-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_gerrZd9ftaZWmVIObH",
      tax_settings: {
        cl_activity_description: "PRODUCCIÓN Y DISTRIBUCIÓN DE GAS"
      },
      name: "Homecenters Peruanos Oriente S.A.C",
      tax_id: "20394077101",
      address: "Camino Melipilla 13120",
      state: "MAIPU",
      email: null,
      phone: null,
      website: null,
      created: "2022-11-29T14:27:31.293555-03:00",
      updated: "2022-11-29T14:27:31.293585-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_rVfBnG7d5b8E1TzlG2",
    labels: [],
    amount: 96949,
    date: "2024-01-20",
    discount: null,
    discount_scheme: null,
    exempt: 0,
    metadata: {},
    number: 13974351,
    prefix: null,
    subtotal: null,
    tax: 18420,
    tax_percent: 19,
    total: 115369,
    created: "2024-01-20T12:00:06.680401-03:00",
    updated: "2024-01-20T12:00:06.680433-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_gerrZd9ftaZWmVIObH",
      tax_settings: {
        cl_activity_description: "PRODUCCIÓN Y DISTRIBUCIÓN DE GAS"
      },
      name: "Homecenters Peruanos Oriente S.A.C",
      tax_id: "20394077101",
      address: "Camino Melipilla 13120",
      state: "MAIPU",
      email: null,
      phone: null,
      website: null,
      created: "2022-11-29T14:27:31.293555-03:00",
      updated: "2022-11-29T14:27:31.293585-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_wQtXVWR1DqiaJEn0n8",
    labels: [],
    amount: 85769,
    date: "2024-01-19",
    discount: null,
    discount_scheme: null,
    exempt: 0,
    metadata: {},
    number: 7561730,
    prefix: null,
    subtotal: null,
    tax: 16296,
    tax_percent: 19,
    total: 102065,
    created: "2024-01-24T15:32:16.031147-03:00",
    updated: "2024-01-24T15:32:16.031176-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_7XuggXVNIj5t1OkG6H",
      tax_settings: {
        cl_activity_description: "CAPTACIÓN, DEPURACIÓN Y DISTRIBUCIÓN DE AGUA"
      },
      name: "Homecenters Peruanos Oriente S.A.C",
      tax_id: "20394077101",
      address: "Av. J. M. Carrera nº 5165",
      state: "SAN MIGUEL",
      email: null,
      phone: null,
      website: null,
      created: "2022-11-29T14:01:28.017056-03:00",
      updated: "2022-11-29T14:01:28.017081-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_ph6hVTs59KOVXJxaak",
    labels: [],
    amount: 22847,
    date: "2024-01-19",
    discount: null,
    discount_scheme: null,
    exempt: 0,
    metadata: {},
    number: 865,
    prefix: null,
    subtotal: null,
    tax: 4341,
    tax_percent: 19,
    total: 30000,
    created: "2024-01-19T11:35:31.034954-03:00",
    updated: "2024-01-19T11:35:31.034976-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_LoL8dCz6qcdwDLbP1l",
      tax_settings: {
        cl_activity_description: "VENTA AL POR MENOR DE COMBUSTIBLES PARA"
      },
      name: "Tiendas Peruanas S.A.",
      tax_id: "20493020618",
      address: "BARROS LUCO 950",
      state: "SAN ANTONIO",
      email: null,
      phone: null,
      website: null,
      created: "2024-01-19T11:35:31.007627-03:00",
      updated: "2024-01-19T11:35:31.007654-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_GcH33E2rXHf4pCGw4h",
    labels: [],
    amount: 22847,
    date: "2024-01-19",
    discount: null,
    discount_scheme: null,
    exempt: 0,
    metadata: {},
    number: 445072,
    prefix: null,
    subtotal: null,
    tax: 4341,
    tax_percent: 19,
    total: 30000,
    created: "2024-01-19T11:31:25.317193-03:00",
    updated: "2024-01-19T11:31:25.317526-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_HxX82zPYtx7BcRDS82",
      tax_settings: {
        cl_activity_description: "ESTACION DE SERVICIO"
      },
      name: "Tiendas Peruanas S.A.",
      tax_id: "20493020618",
      address: "Avda. Vicuna Mackenna 360",
      state: "Melipilla",
      email: null,
      phone: null,
      website: null,
      created: "2022-11-29T02:35:47.600065-03:00",
      updated: "2022-11-29T02:35:47.600089-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_ZS22UbMkCE0Tadrb85",
    labels: [],
    amount: 4624,
    date: "2024-01-16",
    discount: null,
    discount_scheme: null,
    exempt: 362020,
    metadata: {},
    number: 9201571,
    prefix: null,
    subtotal: null,
    tax: 879,
    tax_percent: 19,
    total: 367523,
    created: "2024-01-16T21:00:22.265976-03:00",
    updated: "2024-01-16T21:00:22.266003-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_nz7j1k1Fo7FwQmtnDs",
      tax_settings: {
        cl_activity_description: "CONCESION AUTOPISTAS"
      },
      name: "Tiendas Peruanas S.A.",
      tax_id: "20493020618",
      address: "San José 1135 San Bernardo Región Metropolitana Chile",
      state: "San Bernardo",
      email: null,
      phone: null,
      website: null,
      created: "2022-11-29T14:02:43.713763-03:00",
      updated: "2022-11-29T14:02:43.713787-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_7Mt7lh2Zrb8OnipDGe",
    labels: [],
    amount: 1450,
    date: "2024-01-16",
    discount: null,
    discount_scheme: null,
    exempt: 0,
    metadata: {},
    number: 9169897,
    prefix: null,
    subtotal: null,
    tax: 275,
    tax_percent: 19,
    total: 1725,
    created: "2024-01-16T16:31:40.363776-03:00",
    updated: "2024-01-16T16:31:40.363807-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_s2kbrDDVBAOfghkiPB",
      tax_settings: {
        cl_activity_description: "DISTRIBUCIÓN DE ENERGÍA ELÉCTRICA"
      },
      name: "Tiendas Peruanas S.A.",
      tax_id: "20493020618",
      address: "Avenida Argentina Nº1, piso 9",
      state: "Valparaíso",
      email: null,
      phone: null,
      website: null,
      created: "2023-02-14T11:02:48.042023-03:00",
      updated: "2023-02-14T11:02:48.042054-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_HW6h0NF2rsO6KrnzPQ",
    labels: [],
    amount: 368282,
    date: "2024-01-15",
    discount: null,
    discount_scheme: null,
    exempt: 0,
    metadata: {},
    number: 73,
    prefix: null,
    subtotal: null,
    tax: 69974,
    tax_percent: 19,
    total: 438256,
    created: "2024-01-15T18:00:42.524805-03:00",
    updated: "2024-01-15T18:00:42.524834-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_AvqkCfcragc9Qjz3qB",
      tax_settings: {
        cl_activity_description: "ASESORÍAS Y CONSULTORÍAS AGRÍCOLAS"
      },
      name: "Tiendas Peruanas S.A.",
      tax_id: "20493020618",
      address: "CAM. LAS PALMERAS 101 - 1",
      state: "PENAFLOR",
      email: null,
      phone: null,
      website: null,
      created: "2022-11-29T14:31:13.299727-03:00",
      updated: "2022-11-29T14:31:13.299750-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_1f2a0G7BL7SuGlkRsr",
    labels: [],
    amount: 145490,
    date: "2024-01-15",
    discount: null,
    discount_scheme: null,
    exempt: 0,
    metadata: {},
    number: 20839299,
    prefix: null,
    subtotal: null,
    tax: 27644,
    tax_percent: 19,
    total: 200000,
    created: "2024-01-15T14:02:51.546301-03:00",
    updated: "2024-01-15T14:02:51.546331-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_GcMZN70iXZbcU37cJe",
      tax_settings: {
        cl_activity_description: "Distribuidora de Combustibles, Servicio Carga Eléctrica y Exportación"
      },
      name: "Tiendas Peruanas S.A.",
      tax_id: "20493020618",
      address: "El Bosque Norte 0211 / Av. Isidora Goyenechea 2915, Piso 20",
      state: "Las Condes",
      email: null,
      phone: null,
      website: null,
      created: "2022-11-29T12:50:27.216957-03:00",
      updated: "2022-11-29T12:50:27.216979-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_btOVXoCxBpz1LUkFfF",
    labels: [],
    amount: 3423938,
    date: "2024-01-14",
    discount: null,
    discount_scheme: null,
    exempt: 96806,
    metadata: {},
    number: 20965662,
    prefix: null,
    subtotal: null,
    tax: 650548,
    tax_percent: 19,
    total: 4171292,
    created: "2024-01-15T22:00:34.670899-03:00",
    updated: "2024-01-15T22:00:34.670933-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_qCRmJ0YGYUksfMHcPd",
      tax_settings: {
        cl_activity_description: "Distibucion de energía eléctrica"
      },
      name: "Tiendas Peruanas S.A.",
      tax_id: "20493020618",
      address: "AVENIDA PRESIDENTE RIESCO 5561",
      state: "Las Condes",
      email: null,
      phone: null,
      website: null,
      created: "2022-12-05T21:30:52.750947-03:00",
      updated: "2022-12-05T21:30:52.750970-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_VHqlRYSKFTTasJtjU5",
    labels: [],
    amount: 9764,
    date: "2024-01-13",
    discount: null,
    discount_scheme: null,
    exempt: 0,
    metadata: {},
    number: 2589232,
    prefix: null,
    subtotal: null,
    tax: 1855,
    tax_percent: 19,
    total: 11619,
    created: "2024-01-16T11:31:23.739257-03:00",
    updated: "2024-01-16T11:31:23.739303-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_LYbzhNybLDkWfQ7WRS",
      tax_settings: {
        cl_activity_description: "SEGUROS GENERALES, EXCEPTO ACTIVIDADES DE ISAPRES"
      },
      name: "Supermercados Peruanos S.A",
      tax_id: "20100070970",
      address: "MIRAFLORES 222, PISO 17        Nro:   222",
      state: "SANTIAGO",
      email: null,
      phone: null,
      website: null,
      created: "2022-11-29T13:27:01.618490-03:00",
      updated: "2022-11-29T13:27:01.618513-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_lnxujQsZTXPbz5xU08",
    labels: [],
    amount: 21895,
    date: "2024-01-13",
    discount: null,
    discount_scheme: null,
    exempt: 0,
    metadata: {},
    number: 443635,
    prefix: null,
    subtotal: null,
    tax: 4160,
    tax_percent: 19,
    total: 30000,
    created: "2024-01-13T17:00:12.493922-03:00",
    updated: "2024-01-13T17:00:12.493953-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_HxX82zPYtx7BcRDS82",
      tax_settings: {
        cl_activity_description: "ESTACION DE SERVICIO"
      },
      name: "Supermercados Peruanos S.A",
      tax_id: "20100070970",
      address: "Avda. Vicuna Mackenna 360",
      state: "Melipilla",
      email: null,
      phone: null,
      website: null,
      created: "2022-11-29T02:35:47.600065-03:00",
      updated: "2022-11-29T02:35:47.600089-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_LxF3bsZcmhDVEhNcfB",
    labels: [],
    amount: 2208,
    date: "2024-01-12",
    discount: null,
    discount_scheme: null,
    exempt: 0,
    metadata: {},
    number: 38831372,
    prefix: null,
    subtotal: null,
    tax: 419,
    tax_percent: 19,
    total: 2627,
    created: "2024-01-13T05:00:04.945108-03:00",
    updated: "2024-01-13T05:00:04.945129-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_i0ZL2qYvCcUqyhEzJm",
      tax_settings: {
        cl_activity_description: "Banco Comercial"
      },
      name: "Supermercados Peruanos S.A",
      tax_id: "20100070970",
      address: "AGUSTINAS 777",
      state: "Santiago Centro",
      email: null,
      phone: null,
      website: null,
      created: "2022-11-29T14:05:31.103957-03:00",
      updated: "2022-11-29T14:05:31.103979-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_q8jpWYmdierBoQJdDD",
    labels: [],
    amount: 96882,
    date: "2024-01-11",
    discount: null,
    discount_scheme: null,
    exempt: 0,
    metadata: {},
    number: 10801763,
    prefix: null,
    subtotal: null,
    tax: 18408,
    tax_percent: 19,
    total: 115290,
    created: "2024-01-19T17:30:09.340289-03:00",
    updated: "2024-01-19T17:30:09.340321-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_gerrZd9ftaZWmVIObH",
      tax_settings: {
        cl_activity_description: "PRODUCCIÓN Y DISTRIBUCIÓN DE GAS"
      },
      name: "Supermercados Peruanos S.A",
      tax_id: "20100070970",
      address: "Camino Melipilla 13120",
      state: "MAIPU",
      email: null,
      phone: null,
      website: null,
      created: "2022-11-29T14:27:31.293555-03:00",
      updated: "2022-11-29T14:27:31.293585-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  },
  {
    id: "pur_19NMLWIQZZ2yY9VPA6",
    labels: [],
    amount: 0,
    date: "2024-01-11",
    discount: null,
    discount_scheme: null,
    exempt: 54600,
    metadata: {},
    number: 632281,
    prefix: null,
    subtotal: null,
    tax: 0,
    tax_percent: 0,
    total: 54600,
    created: "2024-01-12T10:01:23.597032-03:00",
    updated: "2024-01-12T10:01:23.597062-03:00",
    imported: false,
    document_type: "PE01",
    supplier: {
      id: "sup_bvTLACl1qSvi2CHo2W",
      tax_settings: {
        cl_activity_description: "Operadora de Sistemas Electrónicos"
      },
      name: "Supermercados Peruanos S.A",
      tax_id: "20100070970",
      address: "Isidora Goyenechea 2800",
      state: "Las Condes",
      email: null,
      phone: null,
      website: null,
      created: "2022-11-29T13:42:32.200895-03:00",
      updated: "2022-11-29T13:42:32.200946-03:00",
      country: "PE",
      currency: 'PE'
    },
    taxpayer: null,
    status: "ERM"
  }
]