import { render, staticRenderFns } from "./VTableHeaders.vue?vue&type=template&id=931dfc04&scoped=true"
import script from "./VTableHeaders.vue?vue&type=script&lang=js"
export * from "./VTableHeaders.vue?vue&type=script&lang=js"
import style0 from "./VTableHeaders.vue?vue&type=style&index=0&id=931dfc04&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "931dfc04",
  null
  
)

export default component.exports