export default [
    {
        id: "sup_bGAgOFq2sHLqUjxVC8",
        tax_settings: {
            cl_activity_description: "COM DE VIDRIOS ALUMINIOS Y QUINCALLERIA ESPEJOS E INS Y ART. DE FERRET"
        },
        name: "AMANDA DENEVI SPA",
        tax_id: "20552103816",
        address: "ANGOL 556",
        state: "LIMA",
        email: null,
        phone: null,
        website: null,
        created: "2024-01-22T10:30:44.972563-03:00",
        updated: "2024-01-22T10:30:44.972587-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_FOS1YeYiTSfo1Ibm0S",
        tax_settings: {
            cl_activity_description: "VENTA AL POR MAYOR Y MENOR DE PRODUCTOS PROMOCIONALES Y PUBLICITARIOS"
        },
        name: "COMERCIAL GNB SPA",
        tax_id: "77350428-8",
        address: "MAPOCHO 4855 - 4859",
        state: "TRUJILLO",
        email: null,
        phone: null,
        website: null,
        created: "2024-01-11T21:00:33.863644-03:00",
        updated: "2024-01-11T21:00:33.863667-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_n1PO8UozhMWa1HBbDD",
        tax_settings: {
            cl_activity_description: "COMERCIALIZADORA E INSTALACION DE CORTINAS Y ROLLER"
        },
        name: "BERACA CORTINAJES SPA",
        tax_id: "20538856674",
        address: "SAN MARTIN 553 OF 901   OFIC",
        state: "LIMA",
        email: null,
        phone: null,
        website: null,
        created: "2024-01-02T16:30:34.325338-03:00",
        updated: "2024-01-02T16:30:34.325363-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_U0RvgqpqGmKY6wUNzN",
        tax_settings: {
            cl_activity_description: "RUT SIN INFO EN GIRO"
        },
        name: "JUAN PABLO GALAZ CUEVAS",
        tax_id: "20553856451",
        address: "LAS CODORNICES 647   PARQUE CENTRAL",
        state: "HUALPEN",
        email: null,
        phone: null,
        website: null,
        created: "2023-12-28T08:30:37.972221-03:00",
        updated: "2023-12-28T08:30:37.972245-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_kliVwOvnyBW3uEgFzn",
        tax_settings: {
            cl_activity_description: "COMERCIALIZADORA PRODUCTOS NACIONALES E INTERNACIONALES"
        },
        name: "COMERCIALIZADORA CATHERINE GORIGOITIA E.I.R.L.",
        tax_id: "20480316259",
        address: "JM COUSINO 2079",
        state: "LIMA",
        email: null,
        phone: null,
        website: null,
        created: "2023-12-19T13:30:56.586853-03:00",
        updated: "2023-12-19T13:30:56.586878-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_VqCvECpD7RFiJQEa7h",
        tax_settings: {
            cl_activity_description: "Venta de productos promocionales"
        },
        name: "PRO-LOGO PRODUCTOS PROMOCIONALES CHILE LIMITADA",
        tax_id: "20547825781",
        address: "AVDA. EL PERAL 07078  66",
        state: "LIMA",
        email: null,
        phone: null,
        website: null,
        created: "2023-12-19T12:00:39.538620-03:00",
        updated: "2023-12-19T12:00:39.538658-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_hUdf2lcrnfQyUqaYsr",
        tax_settings: {
            cl_activity_description: "RESTAURANTE"
        },
        name: "ASSADO SPA",
        tax_id: "20603498799",
        address: "Ramon Carrasco 99",
        state: "TRUJILLO",
        email: null,
        phone: null,
        website: null,
        created: "2023-12-14T23:30:36.401791-03:00",
        updated: "2023-12-14T23:30:36.401814-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_c6bfm1ADJhoRkefDuc",
        tax_settings: {
            cl_activity_description: "TRANSPORTE DE CARGA POR CARRETERA, VENTA O COMPRA VENTA POR MENOR, VEHICULOS AUT"
        },
        name: "PULLMAN CARGO S.A",
        tax_id: "20606106883",
        address: "MAIPU 781",
        state: "LIMA",
        email: null,
        phone: null,
        website: null,
        created: "2023-12-14T17:30:37.403956-03:00",
        updated: "2023-12-14T17:30:37.403982-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_933KpWzYBwclSl3HYK",
        tax_settings: {
            cl_activity_description: "SERVICIOS RELACIONADOS CON LA IMPRESIÓN"
        },
        name: "MADUCK SPA",
        tax_id: "20606422793",
        address: "IBIZA 7676 LOTEO ALTO HUECHURAB",
        state: "TRUJILLO",
        email: null,
        phone: null,
        website: null,
        created: "2023-12-14T12:31:23.397144-03:00",
        updated: "2023-12-14T12:31:23.397166-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_WyaGeeGcLLQTZRzxz9",
        tax_settings: {
            cl_activity_description: "ASESOR ADM.*CTA.MOV,TIERRA,OBR MENOR ARRDO.MAQU.Y EQUIP, TRANS CARGA"
        },
        name: "LUIS MAXIMILIANO OPAZO ROMERO",
        tax_id: "20604915351",
        address: "MATTA 90   SECTOR ARENAL",
        state: "TALCAHUANO",
        email: null,
        phone: null,
        website: null,
        created: "2023-12-07T10:30:39.000856-03:00",
        updated: "2023-12-07T10:30:39.000880-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_vBb7IBMxgsWEqc1g9P",
        tax_settings: {
            cl_activity_description: "Representaciones Nac. y Ext."
        },
        name: "Propesca S.A.",
        tax_id: "20605100016",
        address: "Belen 27",
        state: "Trujillo",
        email: null,
        phone: null,
        website: null,
        created: "2023-12-05T11:30:57.765980-03:00",
        updated: "2023-12-05T11:30:57.766014-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_iIYgSiLEQITPbujJAe",
        tax_settings: {
            cl_activity_description: "Soluciones Publicitarias | Carpas | Toldos | Banderas"
        },
        name: "Cacao Creativo SpA",
        tax_id: "20525994741",
        address: "LOS INDUSTRIALES 2943",
        state: "LIMA",
        email: null,
        phone: null,
        website: null,
        created: "2023-12-04T12:30:44.130020-03:00",
        updated: "2023-12-04T12:30:44.130045-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_mjGIVrOHYeF2rb6pI8",
        tax_settings: {
            cl_activity_description: "RESTAURANT DIURNO Y NOCT DE ALCOHOLES Y VTA DE PRODUCTOS A"
        },
        name: "JARPA GARCIA-VINUESA Y COMPANIA LIMITADA",
        tax_id: "20494156211",
        address: "PEDRO AGUIRRE CERDA 350  L 13",
        state: "LIMA",
        email: null,
        phone: null,
        website: null,
        created: "2023-11-29T12:00:43.302056-03:00",
        updated: "2023-11-29T12:00:43.302080-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_umPoOkXUq5wKToJem2",
        tax_settings: {
            cl_activity_description: "COMPRAVENTA DE EQUIPOS DE DISEÑO, SOFTWARE Y PRODUCTOS DISEÑADOS"
        },
        name: "D & L TECNOLOGIA Y AUDIO S.R.L",
        tax_id: "20494099153",
        address: "IRARRÁZAVAL 1805",
        state: "NUNOA",
        email: null,
        phone: null,
        website: null,
        created: "2023-11-28T16:30:36.532231-03:00",
        updated: "2023-11-28T16:30:36.532257-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_UrnDSQUK45hJiLn6qd",
        tax_settings: {
            cl_activity_description: "ACTIVIDADES GENERALES DEL AREA ELECTRICA Y DE CONSTRUCCIÓN"
        },
        name: "ESTUDIO CONTABLE O & RM S.A.C.",
        tax_id: "20538995364",
        address: "BARROS ARANA 184 LT 2   OF1",
        state: "LIMA",
        email: null,
        phone: null,
        website: null,
        created: "2023-11-23T19:00:34.121093-03:00",
        updated: "2023-11-23T19:00:34.121121-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_nEuwGqWCbc0vHRS2wV",
        tax_settings: {
            cl_activity_description: "ASESOR GRAFICO, EMPRESAS DE PUBLICIDAD"
        },
        name: "FERRETERIA E INVERSIONES A & G E.I.R.L..",
        tax_id: "20603049684",
        address: "LA LLANURA 627 DON MARTIN",
        state: "LIMA",
        email: null,
        phone: null,
        website: null,
        created: "2023-11-22T18:00:49.481890-03:00",
        updated: "2023-11-22T18:00:49.481914-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_Fe0pr9XMm2SN96iKpG",
        tax_settings: {
            cl_activity_description: "TALLER DE DESABOLLADURA , PINTURA DE  AUTOMOVILES Y MECANICA EN GENERA"
        },
        name: "IMPORTACIONES FVC EIRL",
        tax_id: "20494074169",
        address: "ANGOL 1280  B",
        state: "LIMA",
        email: null,
        phone: null,
        website: null,
        created: "2023-11-17T13:31:59.087679-03:00",
        updated: "2023-11-17T13:31:59.087722-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_QqL74JPEQGoSXzYw2Y",
        tax_settings: {
            cl_activity_description: "IMPORTACIÓN, COMERCIALIZACIÓN, DISTRIBUCIÓN, EPP, ELECTRONICA, INSUMOS, REP"
        },
        name: "INVERSIONES Y SERVICIOS DEL ROSARIO",
        tax_id: "20494100186",
        address: "ahumada 254, 806",
        state: "lima",
        email: null,
        phone: null,
        website: null,
        created: "2023-11-16T14:30:50.092073-03:00",
        updated: "2023-11-16T14:30:50.092100-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_tzu0QEeW0eomVKqL1n",
        tax_settings: {
            cl_activity_description: "AGRIC.PROP.CULTIVO HORTALIZ. FRUTALES PLANTAS Y FLORES"
        },
        name: "NEGOCIO Y ALOJAMIENTO QUINTA GUZMAN E.I.R.L.",
        tax_id: "20525926665",
        address: "VILLA SERENA KM 14   CAM PUBLICO EL HUAPE",
        state: "LIMA",
        email: null,
        phone: null,
        website: null,
        created: "2023-11-15T10:00:35.954521-03:00",
        updated: "2023-11-15T10:00:35.954553-03:00",
        country: "PE",
        currency: "PEN"
    },
    {
        id: "sup_87CSPFwwSGU1oXRWh3",
        tax_settings: {
            cl_activity_description: "FABRICACION DE PAN, PRODUCTOS DE PANADERIA Y PASTELERIA"
        },
        name: "NEGOCIOS WAIMAKU E.I.R.L.",
        tax_id: "20542259117",
        address: "VICTORIA ST 19 LOMAS COLORADAS",
        state: "LIMA",
        email: null,
        phone: null,
        website: null,
        created: "2023-11-08T18:00:47.893538-03:00",
        updated: "2023-11-08T18:00:47.893562-03:00",
        country: "PE",
        currency: "PEN"
    }
]